<template>
    <div class="lfont">
        <v-row>
            <v-col cols="4" md="4" lg="4" v-if="request">
                <h3>ຂໍ້ມູນການຂໍພະນັກງານ</h3>
                <p>ຕຳແໜ່ງ: <span class="text-primary">{{ request.position.name }}</span></p>
                <p>ພະແນກ: <span class="text-primary">{{ request.working_at_department.name }}</span></p>
                <p>ຜູ້ຂໍ: <span class="text-primary">{{ request.requester.name }} {{ request.requester.surname }}</span></p>
                <p>ຕ້ອງການ <span class="text-primary">{{ request.need_qty }}</span> ຕຳແໜ່ງ</p>
                <p>ວັນທີ່ຂໍ: <span class="text-primary">{{ request.created }}</span></p>
                <p>ວັນທີ່ຕ້ອງການ: <span class="text-primary">{{ request.need_date }}</span></p>
                <p>ສະຖານທີ່ເຮັດວຽກ: <span class="text-primary">{{ request.working_place }}</span></p>
                <p>ລາຍລະອຽດຕຳແໜ່ງ:</p>
                <ul>
                    <li>ຕຳແໜ່ງນີ້ແມ່ນ <span class="text-primary">{{ request.reason.name }}</span></li>
                    <li>ສັນຊາດ <span class="text-primary">{{ request.reason.name }}</span></li>
                    <li>ຮູບແບບສັນຍາ <span class="text-primary">{{ request.reason.name }}</span></li>
                    <li>ໜ້າທີ່ຮັບພິຊອບ <span class="text-primary">{{ request.reason.name }}</span></li>
                </ul>
                <p>ໜ້າທີ່ຮັບພິຊອບ: <span class="text-primary">{{ request.responsibilities }}</span></p>
                <p>ຄຸນນະສົມບັດ:</p>
                <ul>
                    <li v-for="pro_item in request.properties" class="text-primary">{{ pro_item.name }}</li>
                </ul>
            </v-col>
            <v-col cols="8" md="8" lg="8">
                <h3>ຂໍ້ມູນການສະໝັກ</h3>
                <table>
                    <tr>
                        <th width="50">#</th>
                        <th>ຊື່ຜູ້ສະໝັກ</th>
                        <th>ທີ່ຢູ່</th>
                        <th>ເບີໂທ</th>
                        <th></th>
                    </tr>
                    <tbody>
                        <tr v-for="(item, index) in aplication" class="text-primary">
                            <td><v-checkbox class="m-0 p-0" v-model="selecting_applicant" :value="item.id"></v-checkbox>
                            </td>
                            <td>
                                {{ item.gender == 'male' ? 'ທ້າວ' : 'ນາງ' }} {{ item.name }} {{ item.surname }}
                            </td>
                            <td>ບ້ານ {{ item.village }}, ເມືອງ {{ item.district.name }}, ແຂວງ {{ item.province.name }}</td>
                            <td>{{ item.tel }}</td>
                            <td>
                                <v-btn icon color="primary" @click="show_detail(item)">
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>

                            </td>
                        </tr>
                    </tbody>
                </table>
                <v-btn depressed color="primary" class="text-white lfont" @click="selection_applicant_apply">
                    ບັນທຶກການເລືອກ
                </v-btn>
            </v-col>
        </v-row>
        <Loading v-if="isLoading" />
        <modalShowApplicantDetail :dialog="dialogShowDetail" :data="applicant_detail" @close="dialogShowDetail = false" />
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import modalShowApplicantDetail from '@/components/SelectionApplicant/modals/modalShowApplicantDetail'
export default {
    components: {
        Loading,
        modalShowApplicantDetail,
    },
    data() {
        return {
            dialogShowDetail: false,
            isLoading: false,
            request_id: this.$route.params.id,
            aplication: null,
            request: null,
            selecting_applicant: [],
            applicant_detail: {}
        }
    },
    methods: {
        show_detail(item) {
            this.dialogShowDetail = true;
            this.applicant_detail = item;
            console.log(item)
        },
        fetch_data() {
            this.isLoading = true;
            this.$axios
                .get(`/employee/hr/choose/applicant/${this.request_id}`)
                .then((res) => {
                    if (res.status === 200) {
                        this.aplication = res.data.data.aplication;
                        this.request = res.data.data.request;
                        this.selecting_applicant = res.data.data.request.selection_applicant.map(item => item.apply_job_id);
                    }
                    this.isLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        },
        selection_applicant_apply() {
            this.isLoading = true;
            this.$axios
                .post(`/employee/hr/choose/applicant/${this.request_id}`, { "applicant_apply_job_id": this.selecting_applicant })
                .then((res) => {
                    if (res.status === 200) {
                        this.fetch_data()
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false;
                });
        }
    },
    created() {
        this.fetch_data();
    },

}
</script>

<style lang="scss" scoped></style>